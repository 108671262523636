const messages = {
  en: {
    validations: {
      required: "Field is required.",
      minValue: "Minimum value is {min}.",
      maxValue: `Maximum value is {max}.`,
      length: "Must be exactly {length} characters.",
      maxLength: "Must be shorter than {max} characters",
      minLength: "Must be longer than {min} characters",
      supportedRepositoryProviders: 'Given provider is not supported'
    },
  },
};

export default defineI18nConfig(() => ({
  legacy: false,
  locale: "en",
  messages,
}));
